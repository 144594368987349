module.exports = new Promise((resolve) => {
  const script = document.createElement("script");
  script.src = `remoteEntry.js`;
  script.onload = () => {
    const module = {
      get: (request) => window.spine.get(request),
      init: (arg) => {
        try {
          return window.spine.init(arg);
        } catch (e) {
          console.log("Spine has already been loaded");
        }
      },
    };
    resolve(module);
  }
  document.head.appendChild(script);
});